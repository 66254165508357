export class Product {

  id: number; // id in database
  lpnnCode: string;
  priceManifest: number; //may not need?

  //Main Data
  brand: string;
  title: string;
  imageUrl: string;
  mainImageDataUrlType = {
    dataUrl: '',
    type: ''
  };
  imagesDataUrlType = [{//array of additioinal images data urls and type (png jpg etc.)
    dataUrl: '',
    type: ''
  }];
  additionalImages = [];
  priceAvg180: number;
  categoryRoot: string;
  subCategory: string;
  packageWeight: number;
  itemCondition: string;
  conditionNotes: string;
  amBarcode: string;
  amWarehouseLocation: string;

  //Additional Data
  internalNotes: string;
  packageWidth: number;
  packageLength: number;
  packageHeight: number;
  tags: string;
  serialNumber: string;
  consigner = 'auction_mongers';
  quantity = 1;
  multiplePieces = '0'; // 0 or 1 instead of true false
  asinCode: string;
  upcCode: string;
  orderNum: string;
  orderId: string;
  categoryTree: any[];
  categoryTreeString: string; //json stringified version of the category tree array
  userID: number; //user id of person who inserted data

  //data just for UI
  currentlyChecked = false;


  constructor(){}
}
/** some random products for testing
 *
 *
 *  LPNRR375445838
 *  LPNRR375452445
 *  LPNRR375452503
 *  LPNN200710435
 *  LPNRR375452504
 *  LPNN857548863
 *  LPNN302666335
 *  LPNN064893138
 *  LPNN200710676
 *  LPNN302666089
 */
