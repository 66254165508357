export class Search {

  offset = 0;
  limit = 50; // how many items to show at once
  direction = 'DESC';
  keyword = '';
  //lotCode = '';
  field = 'title'; //or lotcode or upc or asin
  folder = null; //current folder
  userID = 0;

  constructor(){}
}

